import { FC } from "react";
import { Button, Link, theme } from "@chakra-ui/react";
import { InstagramLogo } from "../Components/InstagramLogo";
import { IshqLetterLogo } from "../Components/ishqLetterLogo";
import NavigationBar from "../Components/NavigationBar";
import { IshqLogo } from "../Components/IshqLogo"
import TextLooper from "../Components/TextLooper";

const Home: FC = () => {
    return (
        <div>
            <NavigationBar />
            <div className="h-screen flex flex-col items-center gap-4 margin-unit-2 justify-center">
                <IshqLogo />
                <TextLooper />
                <Button 
                    padding={7} 
                    fontFamily={'inter'} 
                    borderRadius={40} 
                    backgroundColor={'#151313'} 
                    color={'#f7f4f1'} 
                    as="a" 
                    href="https://apps.apple.com/us/app/ishq-south-asian-dating/id1589991588"
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="flat"
                >
                    Apply
                </Button>
            </div>
            <div className="h-20"></div>
            <hr className="border-t border-gray-300 my-8 w-full" />
            <footer className="w-full py-4 bg-transparent">
                <div className="container mx-auto text-center">
                    <Link 
                        href="/privacy-policy" 
                        className="text-sm text-gray-600 hover:text-gray-800 font-inter mr-2"
                    >
                        Privacy Policy
                    </Link>
                    <span className="text-gray-300 mx-2">|</span>
                    <Link 
                        href="/terms-of-service" 
                        className="text-sm text-gray-600 hover:text-gray-800 font-inter mr-2"
                    >
                        Terms of Service
                    </Link>
                    <span className="text-gray-300 mx-2">|</span>
                    <Link 
                        href="/support" 
                        className="text-sm text-gray-600 hover:text-gray-800 font-inter ml-2"
                    >
                        Support
                    </Link>
                </div>
            </footer>
        </div>
    )
}

export default Home;